import React from "react"
import { GivePanel } from "../components/panels/Give-Panel"
import { GivingLegacy } from "../components/Giving-Legacy"
import { DonationImpact } from "../components/Donation-Impact"
import { Seo } from "../components/Seo"
import Helmet from "react-helmet"
import { NewDonationForm } from "../components/New-Donation-Form"
import { DonationFormContainer } from "../components/Donation-Form-Container"

const DonateCopy = () => {
  return (
    <>
      <Helmet>
        <script>
          gl=document.createElement('script');gl.src='https://web.archive.org/web/20211021071504/https://secure.givelively.org/widgets/simple_donation/mlife-foundation.js?show_suggested_amount_buttons=true&show_in_honor_of=true&address_required=false&has_required_custom_question=null&suggested_donation_amounts[]=25&suggested_donation_amounts[]=50&suggested_donation_amounts[]=100&suggested_donation_amounts[]=150&suggested_donation_amounts[]=200';document.getElementsByTagName('head')[0].appendChild(gl);
        </script>
      </Helmet>
      <Seo
        title="Amplify voices, listen to each other needs, and connect"
        description="Your gift helps us create a sacred space where underserved and marginalized individuals of any gender, belief, sexual orientation, or political affiliation."
      />{" "}
      {/* <Nav /> */}
      <GivePanel />
      <DonationImpact />
      <DonationFormContainer />
      <GivingLegacy />
      {/* <Footer /> */}
    </>
  )
}

export default DonateCopy
